import { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";

const ConfirmModal = ({confirm, confirmWhat }) => {

    const [number1, setNumber1] = useState('');
    const [number2, setNumber2] = useState('');
  
    const handleGenerateNumbers = () => {
        const num1 = Math.floor(Math.random() * 9) + 1;
        const num2 = Math.floor(Math.random() * 9) + 1;
        setNumber1(num1);
        setNumber2(num2);
    };

    useEffect(() => {
        handleGenerateNumbers();
    }, []);

    const [numberCheckValue, setNumberCheckValue] = useState('');
    const [timeoutId, setTimeoutId] = useState(null);

    const numberCheckChangeHandler = (event) => {
        setNumberCheckValue(event.target.value);
        if ((number1 + number2) === +event.target.value) {
          confirmWhat[1]();
          confirm(null);
          handleGenerateNumbers();
          setNumberCheckValue('');
          if (timeoutId) {
            clearTimeout(timeoutId); // Clear the previous timeout
          }
        } else {
          if (timeoutId) {
            clearTimeout(timeoutId); // Clear the previous timeout
          }
          const newTimeoutId = setTimeout(() => {
            toast.warning('Rezultat nije dobar!');
          }, 500);
          setTimeoutId(newTimeoutId); // Store the new timeout ID
        }
      };

    return (
        <Modal
            show={!!confirmWhat}
            onHide={() => confirm(null)}
            backdrop="static"
            keyboard={false}
            centered
            size="sm"
            scrollable={true}
            className="add-role-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>{number1 + ' + ' + number2}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12">
                    <input
                        value={numberCheckValue}
                        onChange={numberCheckChangeHandler}
                        id="nameInput"
                        type='number'
                        className="w-100"
                    />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
  
export default ConfirmModal;