import attachButton from "../assets/images/attach-button.png";
import useInput from "../hooks/use-input";
import { useRef, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "./ConfirmModal";
import stars from "../assets/images/stars.png";
import duracell from "../assets/images/logo.png";
import iphone from "../assets/images/iphone.png";
import rabbitBattery from "../assets/images/rabbit_battery.png";
import qrCode from "../assets/images/qr.png";

const HomePage = () => {

    const regax = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

    const targetRef = useRef(null);

    let navigate = useNavigate();

    const [confirmWhat, confirm] = useState();

    const handleScroll = () => {
        targetRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const {
        value: nameValue,
        isValid: nameIsValid,
        hasError: nameHasError,
        valueChangeHandler: nameChangeHandler,
        inputBlurHandler: nameBlurHandler,
        reset: resetName
    } = useInput((value) => value.trim() !== '');

    const {
        value: lastnameValue,
        isValid: lastnameIsValid,
        hasError: lastnameHasError,
        valueChangeHandler: lastnameChangeHandler,
        inputBlurHandler: lastnameBlurHandler,
        reset: resetLastname
    } = useInput((value) => value.trim() !== '');

    const {
        value: emailValue,
        isValid: emailIsValid,
        hasError: emailHasError,
        valueChangeHandler: emailChangeHandler,
        inputBlurHandler: emailBlurHandler,
        reset: resetEmail
    } = useInput((value) => (!value && value.trim() !== '') || regax.test(value) !== false);

    const {
        value: phoneValue,
        isValid: phoneIsValid,
        hasError: phoneHasError,
        valueChangeHandler: phoneChangeHandler,
        inputBlurHandler: phoneBlurHandler,
        reset: resetPhone
    } = useInput((value) => value.trim() !== '');

    const [billImage, setBillImage] = useState(null);

    const handleBillChange = (e) => {
        const selectedImage = e.target.files[0]; // Get the first file selected
        const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        const maxSizeInMB = 5;
        const maxSizeInBytes = maxSizeInMB * 1024 * 1024; // Convert MB to bytes

        if (selectedImage) {
            if (!allowedTypes.includes(selectedImage.type)) {
                toast.warning('Molimo Vas otpremite sliku u odgovarajućem formatu (JPG, JPEG, PNG)!');
                e.target.value = ''; // Reset the input
                setBillImage(null);
                return;
            }

            if (selectedImage.size > maxSizeInBytes) {
                toast.warning(`Molimo Vas otpremite sliku manju od ${maxSizeInMB}MB!`);
                e.target.value = ''; // Reset the input
                setBillImage(null);
                return;
            }

            // Validation passed; update state
            setBillImage(selectedImage);
        }
    };
      

    const [acceptedTerms, setAcceptedTerms] = useState(false);

    const handleCheckboxChange = () => {
      setAcceptedTerms(!acceptedTerms);
    };

    const validationChcek = () => {

        if (!nameIsValid || !lastnameIsValid || !phoneIsValid) {
            toast.warning('Molimo Vas popunite sva polja!');
            return
        }

        if (!emailIsValid) {
            toast.warning('Email nije validan!');
            return
        }

        if (!billImage) {
            toast.warning('Slika računa je obavezno polje!');
            return
        }

        if (!acceptedTerms) {
            toast.warning('Molimo Vas prihvatite uslove korišćenja!');
            return
        }
    }

    const registerService = async () => {

        if (!nameIsValid || !lastnameIsValid || !phoneIsValid) {
            toast.warning('Molimo Vas popunite sva polja!');
            return
        }

        if (!emailIsValid) {
            toast.warning('Email nije validan!');
            return
        }

        if (!billImage) {
            toast.warning('Slika računa je obavezno polje!');
            return
        }

        if (!acceptedTerms) {
            toast.warning('Molimo Vas prihvatite uslove korišćenja!');
            return
        }

        var dataForSave = new FormData();
        dataForSave.append("bill", billImage, billImage.name);
        dataForSave.append("first_name", nameValue);
        dataForSave.append("last_name", lastnameValue);
        dataForSave.append("email", emailValue);
        dataForSave.append("phone", phoneValue);

        try {
            const response = await fetch(
                "https://admin.igrajiosvoji.com/api/participant/register",
                {
                    method: "POST",
                    body: dataForSave
                }
            );
    
            let updatedData = await response.json();
    
            if (!updatedData?.data?.participant_id) {
                throw new Error('Nešto nije u redu. Pokušajte ponovo.');
            }

            toast.success(updatedData?.data?.message ?? 'Uspešno ste se prijavili!');
            sessionStorage.setItem('participantId', updatedData.data.participant_id);
            sessionStorage.setItem('sessionToken', updatedData.data.session_token);
            navigate(`/igra`);
        } catch (error) {
          try {
            const response = await fetch(
                "http://admin.igrajiosvoji.com/api/participant/register",
                {
                    method: "POST",
                    body: dataForSave
                }
            );
    
            let updatedData = await response.json();
    
            if (!updatedData?.data?.participant_id) {
                throw new Error('Nešto nije u redu. Pokušajte ponovo.');
            }

            toast.success(updatedData?.data?.message ?? 'Uspešno ste se prijavili!');
            sessionStorage.setItem('participantId', updatedData.data.participant_id);
            sessionStorage.setItem('sessionToken', updatedData.data.session_token);
            navigate(`/igra`);
          } catch(e) {
            toast.warning('Nešto nije u redu. Pokušajte ponovo.');
          }
        }
    };

    return (
        <div className="container-fluid home-page">
            <div className="row-class">
                <img className="img-fluid formula-img" src={stars} alt="background"></img>
                <div className="d-flex row-first">
                    <div className="col-4">
                        <img className="img-fluid logo-style" src={duracell} alt="logo"></img>
                    </div>
                    <div className="col-8">
                        <div className="row">
                            <div className="col-12 blue-text blue-text-buy">
                                Kupi pakovanje duracell baterija, <br></br>sačuvaj račun i učestvuj u konkursu!
                            </div>
                            <div className="col-12 large-text">
                                Igraj i osvoji vredne <br></br>novogodišnje <br></br>poklone
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex images-container">
                    <div className="col-6">
                        <img className="img-fluid img-rabbit" src={rabbitBattery} alt="secound logo"></img>
                    </div>
                    <div className="col-4 iphone-style">
                        <p className="large-text-x">5x</p>
                        <p className="blue-text blue-text-center">Apple iPhone 16 PRO <br></br>128 GB BLACK Titanium</p>
                        <img className="img-fluid" src={iphone} alt="award"></img>
                    </div>
                </div>
                <div className="d-flex home-text">
                    <div className="col-12 col-md-8">
                        <p>Poseti sajt <a className="white-link" href="/">www.igrajiosvoji.com</a>, unesi ime i prezime i sliku</p>
                        <p>računa na kom se nalaze Duracell baterije.</p>
                        <p>Pokreni igricu i pronađi sakrivene baterije. Najveći broj</p>
                        <p>pronađenih baterija u najbržem vremenskom roku osvojiće</p>
                        <p>vrednu nagradu iPhone 16 Pro 128GB Black Titanium.</p>
                        <p>Konkurs traje od 8.12. do 07.01. Proglašenje pobednika je 14.01.</p>
                        <p className="blue-text pt-2">Poseti <a className="blue-link" href="/">www.igrajiosvoji.com</a> i <a className="blue-link" href="/uslovi-koriscenja">pročitaj pravila</a>!</p>
                    </div>
                    <div className="col-12 col-md-4 qr-container">
                        <img className="qr-style" src={qrCode} alt="qr code" />
                    </div>
                </div>
                {/* <div className="row index-style">
                    <div className="col-12 partners-container">
                        <img className="img-fluid" src={logo} alt="logo"></img>
                        <div className="line"></div>
                        <p>official partner</p>
                        <img className="img-fluid" src={williamsLogo} alt="williams racing"></img>
                    </div>
                    <div className="col-12">
                        <h2 className="gradient-text">igraj i osvoji put u</h2>
                    </div>
                    <div className="col-12">
                        <h1 className="h1-size">Moncu</h1>
                    </div>
                    <div className="col-12">
                        <p>Kupi pakovanje <b>Duracell</b> baterija i učestvuj u konkursu!</p>
                    </div>
                    <div className="col-12">
                        <p className="gold-text">Pronađi što više predmeta u najkraćem vremenskom roku!<br></br>U 3 sobe je 20 uređaja koji koriste baterije.<br></br>Imaš maksimalno vreme od 60 sekundi da pronađeš uređaje.</p>
                    </div>
                </div>
                <div className="w-100 index-style">
                    <button className="submit-button" onClick={() => handleScroll()}>Pokreni igru</button>
                </div>
                <img className="img-fluid formula-img" src={formula} alt="background"></img>
                <img className="img-fluid rabbit-img" src={rabbit} alt="background-second"></img> */}
            </div>
            <div className="container-fluid">
                <div className="row form-wrapper justify-content-center">
                    <div className="col-12 gradient-text-center">
                        <h2 className="gradient-text text-center">Popuni formu</h2>
                    </div>
                    {/* <div className="col-12 text-center">
                        <p className="text-uppercase">Da bi krenuo sa igrom</p>
                    </div> */}
                    <div className="col-md-6 col-12 col-sm-10 col-lg-6 col-xl-4">
                        <p className="white-text text-center pt-3 pb-3">Popuni obavezna polja u nastavku i učestvuj u konkursu koji traje do 7.1.2025. godine! Pronađi 3 Duracell Optimum baterije u roku od 90 sekundi i osvoji priliku za vredne nagrade!
                        5 najbržih takmičara osvojiće iPhone 16 Pro 128GB Black Titanium.</p>
                    </div>
                    <div className="row form-container col-10-xl col-12" ref={targetRef}>
                        <div className="col-6">
                            <p htmlFor="nameInput">Ime:</p>
                            <input
                                value={nameValue}
                                onChange={nameChangeHandler}
                                id="nameInput"
                                type='text'
                            />
                        </div>
                        <div className="col-6 col-6-right">
                            <p htmlFor="lastnameInput">Prezime:</p>
                            <input
                                value={lastnameValue}
                                onChange={lastnameChangeHandler}
                                id="lastnameInput"
                                type='text'
                            />
                        </div>
                        <div className="col-6">
                            <p htmlFor="emailInput">Email:</p>
                            <input
                                value={emailValue}
                                onChange={emailChangeHandler}
                                id="emailInput"
                                type='text'
                            />
                        </div>
                        <div className="col-6 col-6-right">
                            <p htmlFor="phoneInput">Kontakt telefon:</p>
                            <input
                                value={phoneValue}
                                onChange={phoneChangeHandler}
                                id="phoneInput"
                                type='text'
                            />
                        </div>
                    </div>
                </div>
                <div className="row form-container-buttons justify-content-center">
                    <div className="row col-10-xl col-12">
                        <div className="col-6 d-flex align-items-center">
                            <div className="form-buttons-container">
                                <div className="upload-container">
                                    <img htmlFor="fileInput" className="img-fluid" src={attachButton} alt="upload" />
                                    <div className="file-container">
                                        <label htmlFor="fileInput" className="upload-text">Priloži sliku računa</label>
                                        <small>
                                            {
                                                billImage?.name && billImage.name.length > 10
                                                ? billImage.name.slice(0, 5) + '...' + billImage.name.slice(-5)
                                                : billImage?.name ?? ''
                                            }
                                        </small>

                                    </div>
                                    <input type="file" id="fileInput" onChange={handleBillChange} style={{ display: 'none' }} accept=".jpg, .jpeg, .png" />
                                </div>

                                <div className="form-check">
                                    <div>
                                        <input 
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="acceptedTerms"
                                            checked={acceptedTerms}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label className="form-check-label" htmlFor="acceptedTerms">
                                            Prihvatam uslove korišćenja
                                        </label>
                                    </div>
                                    <div onClick={() => navigate(`/uslovi-koriscenja`)} className="terms-style">
                                        Pročitajte uslove korišćenja
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 d-flex justify-content-end">
                            {nameIsValid && lastnameIsValid && phoneIsValid && emailIsValid && billImage && acceptedTerms && (
                                <button className="submit-button" onClick={() => confirm(["Da li ste sigurni?", ()=> registerService() ])}>Pošalji i pokreni</button>
                            )}
                            {(!nameIsValid || !lastnameIsValid || !phoneIsValid || !emailIsValid || !billImage || !acceptedTerms) && (
                                <button className="submit-button" onClick={() => validationChcek()}>Pošalji i pokreni</button>
                            )}
                        </div>
                    </div>
                </div>

                <footer className="text-center footer-container">
                    <img className="img-fluid" src={duracell} alt="logo"></img>
                    <p>Copyright © 2024 Igraj i osvoji. All rights reserved.</p>
                </footer>
            </div>
            <ConfirmModal confirmWhat={confirmWhat} confirm={confirm} />
        </div>
    );
}

export default HomePage;
