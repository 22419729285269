import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import './assets/scss/common.scss';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

const container = document.getElementById('root');

// Create a root.
const root = ReactDOMClient.createRoot(container);

root.render(
  <BrowserRouter basename={'/'}>
    <App />
  </BrowserRouter>,
);
