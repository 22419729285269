import { useEffect, useState } from "react";

const GamePage = () => {
  let id = sessionStorage.getItem("participantId");

  if (!id) {
    window.location.href = '/';
  }

  const game = require("../utils/game");

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(true);
  }, []);

  useEffect(() => {
    // Add or remove class to the body based on the isActive state
    if (isActive) {
      document.body.classList.add('body-overflow'); // Add your CSS class to the body
    } else {
      document.body.classList.remove('body-overflow'); // Remove your CSS class from the body
    }
  }, [isActive]); // Run this effect whenever isActive changes

  return (
    <div></div>
  );
}

export default GamePage;
