import { Navigate, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomaPage";
import GamePage from "./pages/GamePage";
import ResultPage from "./pages/ResultPage";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import UseTermsPage from "./pages/UseTermsPage";

function App() {

  return (
    <>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/igra' element={<GamePage />} />
        <Route path='/rezultat' element={<ResultPage />} />
        <Route path='/uslovi-koriscenja' element={<UseTermsPage />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
      <ToastContainer theme="dark" position="top-right" autoClose={2000} />
    </>
  );
}

export default App;
