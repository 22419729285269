import * as _0xa1aecf from 'pixi.js';

(function (_0x140c58, _0x5836c4) {
    const _0x3af6bd = _0x271e;
    const _0x145af3 = _0x140c58();
    while (!![]) {
        try {
            const _0x46cb1d = -parseInt(_0x3af6bd(0x16b)) / 0x1 + parseInt(_0x3af6bd(0x148)) / 0x2 * (parseInt(_0x3af6bd(0x164)) / 0x3) + -parseInt(_0x3af6bd(0x142)) / 0x4 + parseInt(_0x3af6bd(0x14b)) / 0x5 + parseInt(_0x3af6bd(0x158)) / 0x6 * (-parseInt(_0x3af6bd(0x191)) / 0x7) + parseInt(_0x3af6bd(0x152)) / 0x8 + parseInt(_0x3af6bd(0x13f)) / 0x9 * (-parseInt(_0x3af6bd(0x15b)) / 0xa);
            if (_0x46cb1d === _0x5836c4) {
                break;
            } else {
                _0x145af3['push'](_0x145af3['shift']());
            }
        } catch (_0x2bd647) {
            _0x145af3['push'](_0x145af3['shift']());
        }
    }
}(_0xae1a, 0x851e0));
let startTime;
let app;
let GameState = {
    'TimeLeft': 0x5a,
    'CurrentRoom': 0x1
};
let container;
let itemsFoundTxt;
let xDataFound = [];
async function completeChallenge() {
    const _0x34ab97 = _0x271e;
    let _0x5744c1 = (new Date()['getTime']() - startTime) / 0x3e8;
    try {
        const _0x3ed1b4 = await fetch(_0x34ab97(0x166), {
            'method': 'PUT',
            'headers': {
                'Content-Type': _0x34ab97(0x161),
                'X-Session-Token': sessionStorage[_0x34ab97(0x149)](_0x34ab97(0x141))
            },
            'body': JSON[_0x34ab97(0x13d)]({
                'cGFydGljaXBhbnRfaWQ=': sessionStorage[_0x34ab97(0x149)](_0x34ab97(0x177)),
                'Z2FtZV9lbmRfdGltZQ==': _0x5744c1,
                'cG9zaXRpb24=': btoa(JSON['stringify'](xDataFound))
            })
        });
        const _0x38774b = await _0x3ed1b4['json']();
        sessionStorage[_0x34ab97(0x173)](_0x34ab97(0x156), parseFloat(_0x5744c1[_0x34ab97(0x16f)](0x1)));
        window[_0x34ab97(0x186)][_0x34ab97(0x179)] = _0x34ab97(0x15f);
        sessionStorage[_0x34ab97(0x173)](_0x34ab97(0x18a), itemsFoundCount);
        sessionStorage[_0x34ab97(0x18c)](_0x34ab97(0x177));
        sessionStorage[_0x34ab97(0x18c)](_0x34ab97(0x141));
    } catch (_0x18e229) {
        try {
            const _0xb940fe = await fetch('http://admin.igrajiosvoji.com/api/participant/completeChallenge', {
                'method': 'PUT',
                'headers': {
                    'Content-Type': _0x34ab97(0x161),
                    'X-Session-Token': sessionStorage['getItem']('sessionToken')
                },
                'body': JSON['stringify']({
                    'cGFydGljaXBhbnRfaWQ=': sessionStorage[_0x34ab97(0x149)](_0x34ab97(0x177)),
                    'Z2FtZV9lbmRfdGltZQ==': _0x5744c1,
                    'cG9zaXRpb24=': btoa(JSON[_0x34ab97(0x13d)](xDataFound))
                })
            });
            const _0x2244da = await _0xb940fe[_0x34ab97(0x140)]();
            sessionStorage[_0x34ab97(0x173)](_0x34ab97(0x156), parseFloat(_0x5744c1['toFixed'](0x1)));
            window[_0x34ab97(0x186)][_0x34ab97(0x179)] = '/rezultat';
            sessionStorage[_0x34ab97(0x173)](_0x34ab97(0x18a), itemsFoundCount);
            sessionStorage[_0x34ab97(0x18c)](_0x34ab97(0x177));
        } catch (_0x4c7142) {
            alert(_0x34ab97(0x15d));
        }
    }
}
async function createSprite(_0x36d314, _0x3b8706, _0x59a662) {
    const _0x12c1d7 = _0x271e;
    let _0x18c954 = await _0xa1aecf[_0x12c1d7(0x162)][_0x12c1d7(0x160)](_0x36d314);
    _0x18c954[_0x12c1d7(0x16d)][_0x12c1d7(0x150)] = !![];
    _0x18c954['baseTexture'][_0x12c1d7(0x17a)] = _0xa1aecf[_0x12c1d7(0x193)][_0x12c1d7(0x16e)];
    let _0x2194c7 = new _0xa1aecf[(_0x12c1d7(0x159))](_0x18c954);
    _0x2194c7['pivot']['x'] = _0x2194c7[_0x12c1d7(0x154)] / 0x2;
    _0x2194c7[_0x12c1d7(0x153)]['y'] = _0x2194c7[_0x12c1d7(0x144)] / 0x2;
    if (_0x3b8706) {
        _0x2194c7['x'] = _0x3b8706['x'];
        _0x2194c7['y'] = _0x3b8706['y'];
    }
    if (_0x59a662) {
        _0x2194c7['width'] = _0x59a662['x'];
        _0x2194c7[_0x12c1d7(0x144)] = _0x59a662['y'];
    }
    return _0x2194c7;
}
function getRandomInt(_0x74eb38, _0xe1125) {
    const _0x38e5b5 = _0x271e;
    _0x74eb38 = Math['ceil'](_0x74eb38);
    _0xe1125 = Math[_0x38e5b5(0x17d)](_0xe1125);
    return Math[_0x38e5b5(0x17d)](Math[_0x38e5b5(0x18d)]() * (_0xe1125 - _0x74eb38 + 0x1)) + _0x74eb38;
}
let RandomBatteryPositions = [
    {
        'x': 0x1e,
        'y': -0x32,
        'rot': Math['PI'] / 0x3
    },
    {
        'x': -0x136,
        'y': -0x18b,
        'rot': Math['PI'] / 1.5
    },
    {
        'x': -0x212,
        'y': -0x208,
        'rot': Math['PI'] / 0x3
    },
    {
        'x': -0x258,
        'y': -0xd2,
        'rot': Math['PI'] / 0x3
    },
    {
        'x': -0x3c0,
        'y': 0xaa,
        'rot': Math['PI'] / 1.5
    },
    {
        'x': -0x8c,
        'y': -0x122,
        'rot': Math['PI']
    },
    {
        'x': -0xe6,
        'y': 0x258,
        'rot': Math['PI'] / 0x3
    },
    {
        'x': -0x28,
        'y': 0x30c,
        'rot': Math['PI']
    },
    {
        'x': 0xfa,
        'y': 0x12c,
        'rot': Math['PI'] / 0x3
    },
    {
        'x': 0x14,
        'y': -0x23a,
        'rot': Math['PI'] / 0x3
    },
    {
        'x': 0xc8,
        'y': -0xc8,
        'rot': Math['PI']
    },
    {
        'x': 0x140,
        'y': -0x118,
        'rot': Math['PI'] / 0x3
    },
    {
        'x': 0x258,
        'y': -0x78,
        'rot': Math['PI']
    },
    {
        'x': 0x2bc,
        'y': 0x5,
        'rot': Math['PI'] * 0x2
    },
    {
        'x': 0x41a,
        'y': 0xbe,
        'rot': Math['PI'] / 0x3
    },
    {
        'x': -0x262,
        'y': 0x5,
        'rot': Math['PI'] * 1.1
    },
    {
        'x': 0x2b2,
        'y': 0x96,
        'rot': Math['PI'] / 1.5
    },
    {
        'x': 0x2b2,
        'y': 0x172,
        'rot': Math['PI'] / 1.5
    },
    {
        'x': 0x1a4,
        'y': 0x24e,
        'rot': Math['PI'] / 0x3
    },
    {
        'x': 0x2a8,
        'y': -0x15e,
        'rot': Math['PI'] / 0x3
    },
    {
        'x': -0x3c0,
        'y': -0xbe,
        'rot': Math['PI'] / 0x3
    },
    {
        'x': 0x41a,
        'y': 0xbe,
        'rot': Math['PI'] / 0x3
    }
];
let battery;
let batteries = [];
let itemsFoundCount = 0x0;
let itemCounterTxt;
let COEF = 0x1;
function _0x271e(_0x4e1d08, _0xc704c2) {
    const _0xae1ae9 = _0xae1a();
    _0x271e = function (_0x271e17, _0x43361e) {
        _0x271e17 = _0x271e17 - 0x13c;
        let _0x5e6743 = _0xae1ae9[_0x271e17];
        return _0x5e6743;
    };
    return _0x271e(_0x4e1d08, _0xc704c2);
}
function createText(_0x3ac6df, _0x26cac9, _0x1bae50) {
    const _0x5545f3 = _0x271e;
    let _0x27cafe = new _0xa1aecf[(_0x5545f3(0x171))](_0x3ac6df, {
        'fill': _0x5545f3(0x189),
        'fontSize': _0x26cac9,
        'fontFamily': 'Arial',
        'fontWeight': 'bold',
        'stroke': {
            'color': '#000',
            'width': 0x5,
            'join': _0x5545f3(0x14a)
        }
    });
    _0x27cafe['x'] = _0x1bae50['x'];
    _0x27cafe['y'] = _0x1bae50['y'];
    _0x27cafe[_0x5545f3(0x153)]['x'] = _0x27cafe[_0x5545f3(0x154)] / 0x2;
    _0x27cafe[_0x5545f3(0x153)]['y'] = _0x27cafe['height'] / 0x2;
    return _0x27cafe;
}
function _0xae1a() {
    const _0x3fcbeb = [
        '5365325cnKpaG',
        'set',
        'touchend',
        'Pronađeno',
        'rot',
        'mipmap',
        'appendChild',
        '7849736VWYLZi',
        'pivot',
        'width',
        'addEventListener',
        'position',
        'DEFAULT_SAMPLING',
        '6lgDzoQ',
        'Sprite',
        '0xFFFFFF',
        '10rAysmn',
        'innerHeight',
        'Session\x20expired',
        'data',
        '/rezultat',
        'load',
        'application/json',
        'Assets',
        'view',
        '4257SnvbRg',
        'pointerdown',
        'https://admin.igrajiosvoji.com/api/participant/completeChallenge',
        'canvas',
        'removeChild',
        'rotation',
        'getTime',
        '804471iKKoDI',
        'screen',
        'baseTexture',
        'LINEAR',
        'toFixed',
        'text',
        'Text',
        'Za\x20pomeranje\x20kamere\x20\x0a\x20\x20\x20\x20prevucite\x20mišem',
        'setItem',
        'stage',
        'innerWidth',
        '\x20/\x203',
        'participantId',
        'getLocalPosition',
        'href',
        'scaleMode',
        'length',
        'Vreme\x0aIsteka',
        'floor',
        'touches',
        'push',
        '/assets/battery1.png',
        '/assets/hand.png',
        'devicePixelRatio',
        'interactive',
        '/assets/home.png',
        'renderer',
        'location',
        'Texture',
        'resize',
        'white',
        'score',
        '90S',
        'removeItem',
        'random',
        'addChild',
        'mouseup',
        'TimeLeft',
        '2946454imOlbz',
        'preventDefault',
        'SCALE_MODES',
        'clientX',
        'init',
        '/assets/botmask.png',
        'stringify',
        'add',
        '3789459QNFJpb',
        'json',
        'sessionToken',
        '1981856CVcCee',
        'Point',
        'height',
        '0\x20/\x203',
        'clientY',
        'touchstart',
        '892kfYFVm',
        'getItem',
        'round'
    ];
    _0xae1a = function () {
        return _0x3fcbeb;
    };
    return _0xae1a();
}
function updateTextFound() {
    const _0x4977d3 = _0x271e;
    itemsFoundTxt[_0x4977d3(0x170)] = itemsFoundCount + _0x4977d3(0x176);
}
async function createBatteryOnRandomPosition(_0x85ccd4) {
    const _0x4d2c46 = _0x271e;
    let _0xf68ae5 = await _0xa1aecf[_0x4d2c46(0x162)][_0x4d2c46(0x160)](_0x4d2c46(0x180));
    _0xf68ae5['baseTexture'][_0x4d2c46(0x150)] = !![];
    _0xf68ae5[_0x4d2c46(0x16d)]['scaleMode'] = _0xa1aecf['SCALE_MODES'][_0x4d2c46(0x16e)];
    let _0x4f1a2f = new _0xa1aecf['Sprite'](_0xf68ae5);
    _0x4f1a2f['pivot']['x'] = _0x4f1a2f[_0x4d2c46(0x154)] / 0x2;
    _0x4f1a2f[_0x4d2c46(0x153)]['y'] = _0x4f1a2f[_0x4d2c46(0x144)] / 0x2;
    let _0x48e61c = getRandomInt(0x0, RandomBatteryPositions[_0x4d2c46(0x17b)] - 0x1);
    let _0x4d5854 = RandomBatteryPositions[_0x48e61c];
    _0x4f1a2f[_0x4d2c46(0x156)] = {
        'x': _0x4d5854['x'],
        'y': _0x4d5854['y']
    };
    _0x4f1a2f['width'] = 0xf;
    _0x4f1a2f[_0x4d2c46(0x144)] = 0xf;
    _0x4f1a2f[_0x4d2c46(0x169)] = _0x4d5854[_0x4d2c46(0x14f)];
    _0x4f1a2f[_0x4d2c46(0x183)] = !![];
    _0x4f1a2f['on'](_0x4d2c46(0x165), async _0x147b9f => {
        const _0x59ac0a = _0x4d2c46;
        if (_0x4f1a2f['containsPoint'](_0x147b9f[_0x59ac0a(0x15e)][_0x59ac0a(0x178)](_0x4f1a2f))) {
            _0x85ccd4[_0x59ac0a(0x168)](_0x4f1a2f);
            itemsFoundCount++;
            xDataFound[_0x59ac0a(0x17f)]({
                'index': _0x48e61c,
                'time': (new Date()[_0x59ac0a(0x16a)]() - startTime) / 0x3e8
            });
            updateTextFound();
            if (itemsFoundCount === 0x3) {
                completeChallenge();
            }
        }
    });
    _0x85ccd4[_0x4d2c46(0x18e)](_0x4f1a2f);
    RandomBatteryPositions = RandomBatteryPositions['filter']((_0x39cca7, _0x5a18ab) => _0x5a18ab !== _0x48e61c);
    return _0x4f1a2f;
}
async function createRoomBackgroundSprite() {
    const _0x1f3384 = _0x271e;
    let _0x6e07e5 = await _0xa1aecf[_0x1f3384(0x162)]['load'](_0x1f3384(0x184));
    _0x6e07e5[_0x1f3384(0x16d)][_0x1f3384(0x17a)] = _0xa1aecf[_0x1f3384(0x193)]['LINEAR'];
    _0x6e07e5[_0x1f3384(0x16d)][_0x1f3384(0x150)] = !![];
    let _0x291b39 = new _0xa1aecf['Sprite'](_0x6e07e5);
    _0x291b39[_0x1f3384(0x153)]['x'] = _0x291b39[_0x1f3384(0x154)] / 0x2;
    _0x291b39['pivot']['y'] = _0x291b39[_0x1f3384(0x144)] / 0x2;
    _0x291b39['scale'][_0x1f3384(0x14c)](0.8, 0.8);
    return _0x291b39;
}
((async () => {
    const _0x291d65 = _0x271e;
    app = new _0xa1aecf['Application']();
    await app[_0x291d65(0x195)]({
        'width': window[_0x291d65(0x175)],
        'height': window['innerHeight'],
        'background': _0x291d65(0x15a),
        'resolution': window[_0x291d65(0x182)] || 0x1,
        'autoDensity': !![],
        'antialias': !![]
    });
    _0xa1aecf[_0x291d65(0x187)][_0x291d65(0x157)] = 'linear';
    document['body'][_0x291d65(0x151)](app[_0x291d65(0x167)]);
    startTime = new Date()[_0x291d65(0x16a)]();
    container = new _0xa1aecf['Container']();
    container[_0x291d65(0x153)]['x'] = container[_0x291d65(0x154)] / 0x2;
    container[_0x291d65(0x153)]['y'] = container[_0x291d65(0x144)] / 0x2;
    container['x'] = app[_0x291d65(0x16c)][_0x291d65(0x154)] / 0x2;
    container['y'] = app[_0x291d65(0x16c)][_0x291d65(0x144)] / 0x2;
    app[_0x291d65(0x174)][_0x291d65(0x18e)](container);
    let _0x3aea4c = await createRoomBackgroundSprite();
    container['addChild'](_0x3aea4c);
    for (let _0x252778 = 0x0; _0x252778 < 0x3; _0x252778++) {
        batteries[_0x291d65(0x17f)](await createBatteryOnRandomPosition(container));
    }
    let _0x17c863 = await createSprite(_0x291d65(0x181), {
        'x': window[_0x291d65(0x175)] / 0x2,
        'y': window['innerHeight'] - 0xc8
    });
    _0x17c863['width'] = 0x64;
    _0x17c863['height'] = 0x64;
    let _0x138eee = 0x1;
    let _0xe53a04 = setInterval(() => {
        const _0x27ac47 = _0x291d65;
        _0x17c863[_0x27ac47(0x156)][_0x27ac47(0x14c)](_0x17c863[_0x27ac47(0x156)]['x'] - _0x138eee, _0x17c863[_0x27ac47(0x156)]['y']);
        let _0x479dbf = window[_0x27ac47(0x175)] / 0x2;
        if (_0x17c863['x'] < _0x479dbf - 0x64 || _0x17c863['x'] > _0x479dbf + 0x64) {
            _0x138eee *= -0x1;
        }
    }, 0x1);
    app[_0x291d65(0x174)][_0x291d65(0x18e)](_0x17c863);
    if (window[_0x291d65(0x175)] < 0x2bc || window[_0x291d65(0x15c)] < 0x1f4) {
        COEF = 1.5;
    }
    let _0x422ff5 = await createSprite(_0x291d65(0x13c), {
        'x': window[_0x291d65(0x175)] / 0x2,
        'y': window[_0x291d65(0x15c)] - 0x1e
    });
    if (COEF == 1.5) {
        _0x422ff5[_0x291d65(0x154)] = _0x422ff5[_0x291d65(0x154)] * 1.5 / 0x2;
        _0x422ff5['height'] = _0x422ff5['height'] * 1.5 / 0x2;
    } else {
        _0x422ff5['width'] = _0x422ff5['width'] * 1.5 / COEF;
        _0x422ff5['height'] = _0x422ff5[_0x291d65(0x144)] * 1.5 / COEF;
    }
    app[_0x291d65(0x174)][_0x291d65(0x18e)](_0x422ff5);
    itemsFoundTxt = createText(_0x291d65(0x145), 0x19, {
        'x': window[_0x291d65(0x175)] / 0x2,
        'y': window['innerHeight'] - 0x3c
    });
    if (COEF == 1.5) {
        itemsFoundTxt['y'] += 0xf;
    }
    app[_0x291d65(0x174)]['addChild'](itemsFoundTxt);
    let _0x3ef297 = createText(_0x291d65(0x172), 0x19, {
        'x': window[_0x291d65(0x175)] / 0x2,
        'y': window[_0x291d65(0x15c)] - 0x118
    });
    app[_0x291d65(0x174)][_0x291d65(0x18e)](_0x3ef297);
    let _0x76a570 = createText(_0x291d65(0x14e), 0x19, {
        'x': window[_0x291d65(0x175)] / 0x2,
        'y': window[_0x291d65(0x15c)] - 0x1e
    });
    if (COEF == 1.5) {
        _0x76a570[_0x291d65(0x154)] /= COEF;
        _0x76a570[_0x291d65(0x144)] /= COEF;
        _0x76a570['y'] += 0xa;
    } else {
        _0x76a570[_0x291d65(0x154)] /= COEF;
        _0x76a570[_0x291d65(0x144)] /= COEF;
    }
    app[_0x291d65(0x174)][_0x291d65(0x18e)](_0x76a570);
    let _0x462fe8 = await createSprite('/assets/timerbg.png', {
        'x': window[_0x291d65(0x175)] / 0x2,
        'y': 0x28 / COEF
    });
    _0x462fe8[_0x291d65(0x154)] *= 1.5 / COEF;
    _0x462fe8[_0x291d65(0x144)] *= 1.5 / COEF;
    app[_0x291d65(0x174)]['addChild'](_0x462fe8);
    let _0x3dcd8e = createText(_0x291d65(0x18b), 0x28, {
        'x': window[_0x291d65(0x175)] / 0x2 - 0x32 / COEF,
        'y': 0x28 / COEF
    });
    _0x3dcd8e[_0x291d65(0x154)] /= COEF;
    _0x3dcd8e[_0x291d65(0x144)] /= COEF;
    app['stage'][_0x291d65(0x18e)](_0x3dcd8e);
    let _0x4f8eba = createText(_0x291d65(0x17c), 0x19, {
        'x': window[_0x291d65(0x175)] / 0x2 + 0x32 / COEF,
        'y': 0x28 / COEF
    });
    _0x4f8eba[_0x291d65(0x154)] /= COEF;
    _0x4f8eba[_0x291d65(0x144)] /= COEF;
    app[_0x291d65(0x174)][_0x291d65(0x18e)](_0x4f8eba);
    let _0x56113d = setInterval(() => {
        const _0x58de7 = _0x291d65;
        GameState[_0x58de7(0x190)] -= 0x1;
        _0x3dcd8e[_0x58de7(0x170)] = (GameState[_0x58de7(0x190)] < 0xa ? '0' : '') + GameState[_0x58de7(0x190)] + 'S';
        if (GameState[_0x58de7(0x190)] <= 0x0) {
            completeChallenge();
            clearInterval(_0x56113d);
        }
    }, 0x3e8);
    window['addEventListener'](_0x291d65(0x188), () => {
        const _0x28bd33 = _0x291d65;
        app[_0x28bd33(0x185)][_0x28bd33(0x188)](window[_0x28bd33(0x175)], window['innerHeight']);
        container['x'] = app['screen'][_0x28bd33(0x154)] / 0x2;
        container['y'] = app[_0x28bd33(0x16c)][_0x28bd33(0x144)] / 0x2;
        _0x422ff5['y'] = window[_0x28bd33(0x15c)] - 0x1e;
        _0x422ff5['x'] = window[_0x28bd33(0x175)] / 0x2;
        _0x76a570['y'] = window[_0x28bd33(0x15c)] - 0x1e;
        _0x76a570['x'] = window[_0x28bd33(0x175)] / 0x2;
        if (COEF == 1.5) {
            _0x76a570['y'] += 0xa;
        }
        itemsFoundTxt['x'] = window[_0x28bd33(0x175)] / 0x2;
        itemsFoundTxt['y'] = window[_0x28bd33(0x15c)] - 0x3c;
        if (COEF == 1.5) {
            itemsFoundTxt['y'] += 0xf;
        }
        _0x3dcd8e['x'] = window['innerWidth'] / 0x2 - 0x32 / COEF;
        _0x3dcd8e['y'] = 0x28 / COEF;
        _0x462fe8['x'] = window['innerWidth'] / 0x2;
        _0x462fe8['y'] = 0x28 / COEF;
        _0x4f8eba['x'] = window[_0x28bd33(0x175)] / 0x2 + 0x32 / COEF;
        _0x4f8eba['y'] = 0x28 / COEF;
    });
    let _0x139962 = ![];
    let _0xdb443a = new _0xa1aecf[(_0x291d65(0x143))]();
    let _0x364880 = new _0xa1aecf['Point']();
    app['view']['addEventListener']('mousedown', _0x47420d => {
        const _0x4eb1d2 = _0x291d65;
        _0x139962 = !![];
        _0xdb443a['set'](_0x47420d[_0x4eb1d2(0x194)], _0x47420d[_0x4eb1d2(0x146)]);
        _0x364880[_0x4eb1d2(0x14c)](container['x'], container['y']);
    });
    app[_0x291d65(0x163)][_0x291d65(0x155)](_0x291d65(0x18f), () => {
        _0x139962 = ![];
    });
    app[_0x291d65(0x163)]['addEventListener']('mousemove', _0xa97644 => {
        const _0x5871b5 = _0x291d65;
        if (_0x139962) {
            const _0x5cb60a = _0xa97644['clientX'] - _0xdb443a['x'];
            const _0x280904 = _0xa97644[_0x5871b5(0x146)] - _0xdb443a['y'];
            container['x'] = _0x364880['x'] + _0x5cb60a;
            container['y'] = _0x364880['y'] + _0x280904;
            if (container['x'] < -0x12c) {
                container['x'] = -0x12c;
            }
            if (container['x'] > 0x640) {
                container['x'] = 0x640;
            }
            if (container['y'] < -0xfa) {
                container['y'] = -0xfa;
            }
            if (container['y'] > 0x44c) {
                container['y'] = 0x44c;
            }
            if (_0x3ef297 !== null) {
                app[_0x5871b5(0x174)][_0x5871b5(0x168)](_0x3ef297);
                _0x3ef297 = null;
            }
            if (_0x17c863 !== null) {
                app[_0x5871b5(0x174)][_0x5871b5(0x168)](_0x17c863);
                _0x17c863 = null;
                clearInterval(_0xe53a04);
            }
        }
    });
    function _0x5476f4(_0x537305) {
        const _0x255592 = _0x291d65;
        if (_0x537305[_0x255592(0x17e)] && _0x537305['touches']['length'] > 0x0) {
            return {
                'x': _0x537305[_0x255592(0x17e)][0x0][_0x255592(0x194)],
                'y': _0x537305[_0x255592(0x17e)][0x0][_0x255592(0x146)]
            };
        }
        return {
            'x': _0x537305[_0x255592(0x194)],
            'y': _0x537305[_0x255592(0x146)]
        };
    }
    app[_0x291d65(0x163)][_0x291d65(0x155)](_0x291d65(0x14d), () => {
        _0x139962 = ![];
    });
    app[_0x291d65(0x163)][_0x291d65(0x155)](_0x291d65(0x147), _0x3d5d2e => {
        const _0x24d0f7 = _0x291d65;
        _0x139962 = !![];
        const _0x3e0df3 = _0x5476f4(_0x3d5d2e);
        _0xdb443a[_0x24d0f7(0x14c)](_0x3e0df3['x'], _0x3e0df3['y']);
        _0x364880['set'](container['x'], container['y']);
        _0x3d5d2e[_0x24d0f7(0x192)]();
    });
    app[_0x291d65(0x163)][_0x291d65(0x155)]('touchmove', _0x3adad1 => {
        const _0x53a7ab = _0x291d65;
        if (_0x139962) {
            const _0x4cae7b = _0x5476f4(_0x3adad1);
            const _0x1bfd7b = _0x4cae7b['x'] - _0xdb443a['x'];
            const _0xc81a1b = _0x4cae7b['y'] - _0xdb443a['y'];
            container['x'] = _0x364880['x'] + _0x1bfd7b;
            container['y'] = _0x364880['y'] + _0xc81a1b;
            if (container['x'] < -0x3e8) {
                container['x'] = -0x3e8;
            }
            if (container['x'] > 0x640) {
                container['x'] = 0x640;
            }
            if (container['y'] < -0xfa) {
                container['y'] = -0xfa;
            }
            if (container['y'] > 0x44c) {
                container['y'] = 0x44c;
            }
            if (_0x3ef297 !== null) {
                app['stage']['removeChild'](_0x3ef297);
                _0x3ef297 = null;
            }
            if (_0x17c863 !== null) {
                app[_0x53a7ab(0x174)][_0x53a7ab(0x168)](_0x17c863);
                _0x17c863 = null;
                clearInterval(_0xe53a04);
            }
            _0x3adad1[_0x53a7ab(0x192)]();
        }
    });
    app['ticker'][_0x291d65(0x13e)](_0x5c8b60 => {
    });
})());