import { useEffect, useState } from "react";
import logo from "../assets/images/logo.png";
import device1 from "../assets/images/battery1.png";
// import device2 from "../assets/images/battery2.png";
// import device3 from "../assets/images/battery3.png";
// import device4 from "../assets/images/devices/4.png";
// import device5 from "../assets/images/devices/5.png";
// import device6 from "../assets/images/devices/6.png";
// import device7 from "../assets/images/devices/7.png";
// import device8 from "../assets/images/devices/8.png";
// import device9 from "../assets/images/devices/9.png";
// import device10 from "../assets/images/devices/10.png";
// import device11 from "../assets/images/devices/11.png";
// import device12 from "../assets/images/devices/12.png";
// import device13 from "../assets/images/devices/13.png";
// import device14 from "../assets/images/devices/14.png";
// import device15 from "../assets/images/devices/15.png";
// import device16 from "../assets/images/devices/16.png";
// import device17 from "../assets/images/devices/17.png";
// import device18 from "../assets/images/devices/18.png";
// import device19 from "../assets/images/devices/19.png";
// import device20 from "../assets/images/devices/20.png";
// import device21 from "../assets/images/devices/21.png";
import { useNavigate } from "react-router-dom";

const ResultPage = () => {

    let navigate = useNavigate();

    const images = [
        { image: device1, id: '1.png', name: 'Duracell Optimum' },
        { image: device1, id: '2.png', name: 'Duracell Optimum' },
        { image: device1, id: '3.png', name: 'Duracell Optimum' },
        // { image: device4, id: '4.png', name: 'Apple airtag' },
        // { image: device5, id: '5.png', name: 'Zvučnik' },
        // { image: device6, id: '6.png', name: 'Aparat' },
        // { image: device7, id: '7.png', name: 'Četkica za zube' },
        // { image: device8, id: '8.png', name: 'Daljinski za klimu' },
        // { image: device9, id: '9.png', name: 'Daljinski za tv' },
        // { image: device10, id: '10.png', name: 'Digitalni toplometar' },
        // { image: device11, id: '11.png', name: 'Ključevi' },
        // // { image: device12, id: '12.png', name: 'Kuhinjska vaga' },
        // { image: device13, id: '13.png', name: 'Kompljuterski mis' },
        // { image: device14, id: '14.png', name: 'Senzor temperature' },
        // { image: device15, id: '15.png', name: 'Brijač' },
        // { image: device16, id: '16.png', name: 'Senzor dima' },
        // { image: device17, id: '17.png', name: 'Tastatura' },
        // { image: device18, id: '18.png', name: 'Tranzistor' },
        // { image: device19, id: '19.png', name: 'Uredjaj protiv komaraca' },
        // { image: device20, id: '20.png', name: 'Vaga' },
        // { image: device21, id: '21.png', name: 'Zvono' }
    ];

    const [productDetails, setProductDetails] = useState([]);
    const [position, setPosition] = useState(null);
    const [score, setScore] = useState(0);

    useEffect(() => {
        const itemsFound = JSON.parse(sessionStorage.getItem('itemsFound'));
        setProductDetails(itemsFound?.map(path => path.split('/').pop()));
        setPosition(sessionStorage.getItem('position'));
        setScore(sessionStorage.getItem('score'));
    }, []);

    return (
        <div className="result-page">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-12 text-center">
                        <img onClick={() => navigate(`/`)} className="img-fluid logo-result-page" src={logo} alt="logo"></img>
                    </div>
                    <div className="col-12 d-flex justify-content-center">
                        <h2 className="gradient-text-result">Vreme završetka igre</h2>
                    </div>
                    <div className="col-12 d-flex justify-content-center">
                        <h1 className="position-style">{ position > 0 ? position : 0 }</h1>
                    </div>
                    {/* col-xl-8 col-lg-10 col-md-10 col-12 */}
                    <div className="devices-container">
                        <div className="row">
                            {images.length > 0 && images.map((i, index) => {
                                return (
                                    <div className={index < sessionStorage.getItem('score') ? 'device-item active' : 'device-item'} key={index}>
                                        <div>
                                            <img className="img-fluid" src={i.image} alt={i.name}></img>
                                        </div>
                                        {/* <p>{i.name}</p> */}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="col-12 score-wrapper">
                        <h1 className="gradient-text-result">Pronašli ste:</h1>
                        <div className="score-container">
                            <h1>{ score > 0 ? score : 0 }</h1>
                            <div className="line"></div>
                            <h1 className="text-gold">{ images.length }</h1>
                        </div>
                    </div>
                    <div className="col-12 text-center">
                        <button onClick={() => navigate(`/`)} className="submit-button">Pokušajte opet</button>
                        <p className="mt-3 submit-text">Da bi Vam novi rezultat bio validan, koristite novi račun.</p>
                    </div>

                    <footer className="text-center footer-container">
                        <img onClick={() => navigate(`/`)} className="img-fluid" src={logo} alt="logo"></img>
                        <p>Copyright © 2024 Igraj i osvoji. All rights reserved.</p>
                    </footer>
                </div>
            </div>
        </div>
    );
}

export default ResultPage;
